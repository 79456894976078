<template>
    <div class="guide-collected-page">
        <div class="cont ">
            <div class="wid1200">
                <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            </div>
            <el-row style="margin-top: 20px;">
                <el-col :span="17">
                    <!--<div class="search_header">-->
                    <!--<el-input type="text" placeholder="请输入关键词"></el-input><el-button>立即检索</el-button>-->
                    <!--</div>-->
                    <div class="tab_box">
                        <span>共<i style="font-style: normal;"> {{totalNum}} </i>种集刊</span>
                        <el-input class="fr" type="text" v-model="iedolName" placeholder="请输入集刊名称" suffix size="mini"
                            @keyup.enter.native="toSearch"><i class="el-icon-search el-input__icon" slot="suffix"
                                @click="toSearch"></i></el-input>
                    </div>
                    <div class="tab_box tab_box_last">
                        <span v-if="searchName">检索词：{{searchName}}<i class="el-icon-close" style="cursor:pointer;"
                                @click="clearSearchname"></i></span>
                        <span class="fr">切换视图：<a @click="showTable" :class="{'on':ablesShow}">文章列表</a>|<a
                                @click="showTable" :class="{'on':!ablesShow}">摘要列表</a></span>
                    </div>
                    <el-table :data="overViewList" stripe style="width: 100%" v-show="ablesShow"
                        :header-cell-style="{background:'#BD1A2D',color:'#fff'}">
                        <el-table-column label="刊名">
                            <template slot-scope="scope">
                                <router-link style="color: #292929" :to="{path:'/c/c', query:{id:scope.row.id,  pN: encodeURI(pageName.slice(0, 13))}}">
                                    {{scope.row.name}}</router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cycle" width="180" label="出版周期">
                        </el-table-column>
                        <el-table-column prop="cnki" label="被收录数据库" width="180">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.cssci==1">CSSCI</span>
                                <span v-if="scope.row.ami==1">AMI入库</span>
                                <span v-if="scope.row.amiCore==1">AMI核心</span>
                                <span v-if="scope.row.cnki==1">&nbsp;&nbsp;CNKI</span>
                                <span v-if="scope.row.directoriesDate==1">&nbsp;&nbsp;CNI名录集刊 </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="count" label="收录周期" width="180" align="center">
                        </el-table-column>
                    </el-table>
                    <div class="tabList" v-show="!ablesShow">
                        <div class="tab_item clear-fix" v-for="(item, index) in overViewList" :key="index"
                            @click="goLink(item)">
                            <img :src="item.imgPath + '?w=150&h=220'" class="fl" alt="">
                            <div class="fr">
                                <h5>{{item.name}} <span class="included-database" v-if="item.cnki==1 || item.ami==1 || item.amiCore==1 || item.cssci==1 || item.directoriesDate==1"><i></i>
                                <!-- 被收录数据库： -->
                                    <span v-if="item.cssci==1">CSSCI</span>
                                    <!-- &nbsp;&nbsp; -->
                                    <span v-if="item.ami==1">AMI入库</span>
                                    <span v-if="item.amiCore==1">AMI核心</span>
                                    <span v-if="item.cnki==1">CNKI</span>
                                    <span v-if="item.directoriesDate==1">CNI名录集刊 </span>
                                            </span>
                                    </h5>
                                <p><span>主编：{{item.author}}</span><span>出版单位：{{item.publisher}}</span><span>创刊时间：{{item.firstDate}}</span>
                                </p>
                                <p><span>所属学科：{{item.subject}}</span><span>集刊周期：{{item.cycle}}</span></p>
                                <p><span>主办单位： {{item.insNames}}</span></p>
                                <p class="abs">集刊简介：{{item.description}}</p>
                            </div>
                        </div>
                        <div class="el-table__empty-block" v-if="overViewList.length==0"><span
                                class="el-table__empty-text">暂无数据</span> </div>
                    </div>
                    <div style="text-align: right">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="curpage" :page-sizes="[100, 200, 300, 400]" :page-size="10"
                            layout="total, prev, pager, next, jumper" :total="totalNum">
                        </el-pagination>
                    </div>
                </el-col>
                <el-col :span="7" style="margin-top: 20px;">
                    <div class="tree_box" :class="{'on':showSort}">
                        <h5 class="tree_sort" @click="changeSort(0)"><i :class="{'on':showSort}"></i>集刊分类导航</h5>
                        <div class="sort_box" v-show="showSort">
                            <p :class="{all:classifyId==''}" @click="changeClassify()"><i><</i>全部 </p>
                            <p :class="{all:classifyId==item.id}" v-for="(item, index) in typeList" :key="index" @click="changeClassify(item.id)"><i><</i>{{item.name}} </p>
                        </div>
                    </div>
                    <div class="tree_box" :class="{'on':showSort1}" style="display: none;">
                        <h5 class="tree_sort" @click="changeSort(1)"><i :class="{'on':showSort1}"></i>学科分类导航</h5>
                        <div class="sort_box" v-show="showSort1">
                            <p class="all"><i>
                                <</i>全部 </p> <p v-for="(item, ind) in subjectlist" :key="ind">{{item.name}}
                            </p>
                        </div>
                    </div>
                    <div class="tree_box" :class="{'on':showSort2}">
                        <h5 class="tree_sort" @click="changeSort(2)"><i :class="{'on':showSort2}"></i>被收录数据库导航</h5>
                        <div class="sort_box" v-show="showSort2">
                            <p :class="{all:cssciChoose==''&&amiChoose==''&&amiChoose==''&&cnkiChoose==''}" @click="changeType(0,'')"><i><</i>全部 </p>
                            <p :class="{all:cssciChoose==1}" @click="changeType(0,'CSSCI')"><i><</i>CSSCI </p>
                            <p :class="{all:amiChoose==1}" @click="changeType(0,'AMI')"><i><</i>AMI入库 </p>
                            <p :class="{all:amiCoreChoose==1}" @click="changeType(0,'AMICORE')"><i><</i>AMI核心 </p>
                            <p :class="{all:cnkiChoose==1}" @click="changeType(0,'CNKI')"><i><</i>CNKI </p>
                        </div>
                    </div>
                    <div class="tree_box" :class="{'on':showSort3}">
                        <h5 class="tree_sort" @click="changeSort(3)">
                            <i :class="{'on':showSort3}"></i>刊期导航</h5>
                        <div class="sort_box" v-show="showSort3">
                        <p :class="{all:cycleType==''}" @click="changeType(1,'')"><i><</i>全部 </p>
                        <p :class="{all:cycleType==item}" v-for="(item, ind) in qikanList" :key="ind" @click="changeType(1,item)"><i><</i>{{item}} </p>
                        </div>
                    </div>
                    <div class="tree_box" :class="{'on':showSort4}">
                        <h5 class="tree_sort" @click="changeSort(4)"><i :class="{'on':showSort4}"></i>优秀集刊导航</h5>
                        <div class="sort_box" v-show="showSort4">
                            <p :class="{all:yearChoose==''}" @click="changeType(2,'')"><i><</i>全部</p>
                            <p :class="{all:yearChoose==item}" v-for="(item, ind) in excllentList" :key="ind" @click="changeType(2,item)"><i><</i>{{item}}</p>
                        </div>
                    </div>
                    <div class="tree_box" :class="{'on':showSort5}">
                        <h5 class="tree_sort" @click="changeSort(5)"><i :class="{'on':showSort5}"></i>名录集刊导航</h5>
                        <div class="sort_box" v-show="showSort5">
                            <p :class="{all:directoryCollect===''}" @click="changeType(3, '')"><i><</i>全部</p>
                            <p :class="{all:directoryCollect===1}"  @click="changeType(3, 1)"><i><</i>名录集刊</p>
                            <!-- <p :class="{all:directoryCollect==item}" v-for="(item, ind) in directoryCollectList" :key="ind" @click="changeType(3,item)"><i><</i>{{item}}</p> -->
                        </div>
                    </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    name: 'collectOverview',
    data() {
        return {
            ablesShow: false,
            pageName: '集刊导览',
            pN: '',
            typeList: [
                {
                    name: '宗教哲学',
                    id: '8448'
                },
                {
                    name: '经济管理',
                    id: '8458'
                },
                {
                    name: '社会政法',
                    id: '8459'
                },
                {
                    name: '文化传媒',
                    id: '8460'
                },
                {
                    name: '历史地理',
                    id: '8461'
                },
                {
                    name: '语言文学',
                    id: '8463'
                }
            ],
            tableData: [
                {
                    suoure: 'CSSSI',
                    name: '中国经济特区研究',
                    address: '一年一刊',
                    num: '10'
                },
                {
                    suoure: 'CSSSI CNKI',
                    name: '民国研究',
                    address: '一年两刊',
                    num: '10'
                },
                {
                    suoure: 'CSSSI',
                    name: '韩国研究论丛',
                    address: '一年两刊',
                    num: '10'
                }
            ],
            overViewList: [],
            subjectlist: [
                {
                    name: '环境科学技术'
                },
                {
                    name: '安全科学技术'
                },
                {
                    name: '管理学'
                },
                {
                    name: '马克思主义'
                },
                {
                    name: '哲学'
                },
                {
                    name: '宗教学'
                },
                {
                    name: '语言学'
                },
                {
                    name: '文学'
                },
                {
                    name: '艺术学'
                },
                {
                    name: '历史学'
                },
                {
                    name: '考古学'
                },
                {
                    name: '经济学'
                },
                {
                    name: '政治学'
                },
                {
                    name: '法学'
                },
                {
                    name: '军事学'
                },
                {
                    name: '社会学'
                },
                {
                    name: '民族学'
                },
                {
                    name: '新闻学与传播学'
                },
                {
                    name: '图书馆、情报与文献学'
                },
                {
                    name: '教育学'
                },
                {
                    name: '体育科学'
                },
                {
                    name: '统计学'
                },
                {
                    name: '预防医学与卫生学'
                }
            ],
            qikanList: ['一年一辑', '一年两辑', '一年三辑', '一年四辑', '其他'],
            excllentList: [],
            directoryCollectList: [],
            showSort: true,
            showSort1: false,
            showSort4: false,
            showSort3: false,
            showSort2: false,
            showSort5: false,
            curpage: 1,
            totalNum: 0,
            classifyId: '',
            cssciChoose: '',
            amiChoose: '',
            amiCoreChoose: '',
            cnkiChoose: '',
            cycleType: '',
            yearChoose: '',
            directoryCollect: '',
            iedolName: '',
            searchName: ''
        }
    },
    components: { BreadCrumb },
    created() {
        this.getDateYear()
        // this.getDirectoryCollect()
        // if(this.$route.params.id == 0){
        //     this.showSort2 = true;
        //     this.showSort = false;
        //     this.cssciChoose = 1
        // }else if(this.$route.params.id == 1){
        //     this.showSort2 = true;
        //     this.showSort = false;
        //     this.cnkiChoose = 1
        // }else if(this.$route.params.id >2014){
        //     this.yearChoose = this.$route.params.id;
        //     this.showSort4 = true;
        //     this.showSort = false;
        // }
        if(this.$route.query.id >2014){
            this.yearChoose = this.$route.query.id
            this.showSort4 = true;
            this.showSort = false;
        }
        this.getOverviewList()
    },
    methods: {
        getDateYear() {
            var _this = this
            _this.$http.get('/admin/api/cp/excellentYears', {
                params: {
                    all: true
                }
            }).then(res => {
                _this.excllentList = res.data
            })
        },
        getDirectoryCollect () {
            var _this = this
            _this.$http.get('/admin/api/cp/directories', {
                params: {
                    all: true
                }
            }).then(res => {
                _this.directoryCollectList = res.data.data
            })
        },
        handleSizeChange(val) {},
        handleCurrentChange(val) {
            this.curpage = val
            this.getOverviewList()
        },
        showTable() {
            this.ablesShow = !this.ablesShow
            this.curpage = 1
            this.getOverviewList()
        },
        changeSort(num) {
            // this.curpage = 1
            if (num == 0) {
                this.showSort = !this.showSort
            } else if (num == 1) {
                this.showSort1 = !this.showSort1
            } else if (num == 2) {
                this.showSort2 = !this.showSort2
            } else if (num == 3) {
                this.showSort3 = !this.showSort3
            } else if (num == 4) {
                this.showSort4 = !this.showSort4
            } else if (num == 5) {
                this.showSort5 = !this.showSort5
            }
        },
        changeClassify(id) {
            this.curpage = 1
            if (id) {
                this.classifyId = id
            } else {
                this.classifyId = ''
            }
            this.getOverviewList()
        },
        changeType(type, num) {
            this.curpage = 1

            this.classifyId = ''
            this.cssciChoose = ''
            this.amiChoose = ''
            this.amiCoreChoose = ''
            this.cnkiChoose = ''
            this.cycleType = ''
            this.yearChoose = ''
            this.directoryCollect = ''

            if (type == 0) {
                if (num == 'CSSCI') {
                    this.cssciChoose = 1
                    this.amiChoose = ''
                    this.amiCoreChoose = ''
                    this.cnkiChoose = ''
                } else if (num == 'AMI') {
                  this.cssciChoose = ''
                  this.amiChoose = 1
                  this.amiCoreChoose = ''
                  this.cnkiChoose = ''
                }  else if (num == 'AMICORE') {
                    this.cssciChoose = ''
                    this.amiChoose = ''
                    this.amiCoreChoose = 1
                    this.cnkiChoose = ''
                } else if (num == 'CNKI') {
                    this.cssciChoose = ''
                    this.amiChoose = ''
                    this.amiCoreChoose = ''
                    this.cnkiChoose = 1
                } else {
                    this.cssciChoose = ''
                    this.amiChoose = ''
                    this.amiCoreChoose = ''
                    this.cnkiChoose = ''
                }
            } else if (type == 1) {
                if (num) {
                    this.cycleType = num
                } else {
                    this.cycleType = ''
                }
            } else if (type == 2) {
                if (num) {
                    this.yearChoose = num
                } else {
                    this.yearChoose = 0
                }
            } else if (type == 3) {
                // if (num == 0) {
                //     this.directoryCollect = 0
                // } else {
                //     this.directoryCollect = ''
                // }
                this.directoryCollect = num

            }
            this.getOverviewList()
        },
        toSearch() {
            this.searchName = this.iedolName
            this.curpage = 1
            this.classifyId = ''
            this.cssciChoose = ''
            this.amiChoose = ''
            this.amiCoreChoose = ''
            this.cycleType = ''
            this.yearChoose = ''
            this.directoryCollect = ''
            this.getOverviewList()
        },
        clearSearchname() {
            this.searchName = ''
            this.getOverviewList()
        },
        getOverviewList() {
            var _this = this
            _this.$http
                .get('/admin/api/cp/list', {
                    params: {
                        classifyId: this.classifyId,
                        cnki: this.cnkiChoose,
                        cssci: this.cssciChoose,
                        ami: this.amiChoose,
                        amiCore: this.amiCoreChoose,
                        cycle: this.cycleType,
                        excellentDate: this.yearChoose,
                        directoriesDate: this.directoryCollect,
                        pageNum: this.curpage,
                        pageSize: 10,
                        name: this.searchName
                    }
                })
                .then(res => {
                    this.overViewList = res.data.data.rows
                    this.totalNum = res.data.data.total
                    this.iedolName = ''
                })
        },
        goLink(item) {
            // console.log('item', item)
            this.$router.push({path:'/c/c', query:{id: item.id,  pN: encodeURI(this.pageName)}})
            // var routerData = this.$router.resolve({
            //     name: 'iedol',
            //     params: {
            //         id: id
            //     }
            // })
            // window.open(routerData.href, '_blank')




            // this.$router.push({name:'iedol',params:{id:id}})
        }
    }
}
</script>

<style scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.search_header .el-input {
    width: 752px;
    display: inline-block;
}
.search_header .el-input input {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.tab_box {
    padding: 0 20px;
    /*margin-top: 20px;*/
    margin-bottom: 0px;
    border: 2px solid #dff1f1;
    padding-right: 10px;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
    color: #808080;
}
.tab_box .el-input {
    width: 217px;
    line-height: 43px;
}
.tab_box .el-input >>> .el-icon-search {
    color: #BD1A2D;
    font-weight: 700;
    cursor: pointer;
}
.tab_box.tab_box_last {
    border: none;
    padding-right: 0;
    margin-bottom: 0px;
}
.tab_box > span > i {
    color: #BD1A2D;
}
.tab_box .fr a {
    padding: 0 10px;
}
.tab_box .fr a:hover {
    color: #e58e88;
}
.tab_box .fr a.on {
    color: #BD1A2D;
}
.tab_box .fr a:last-child {
    margin-right: 0;
}
.tab_item {
    padding: 12px 0 20px;
    border-bottom: 1px dashed #b4b4b4;
}
.tab_item > img {
    width: 150px;
    height: 200px;
    background: #eeeeee;
    border: 1px solid #ccc;
    box-sizing: border-box;
    cursor: pointer;
}
.tab_item > .fr {
    width: 660px;
    padding-top: 10px;
}
.tab_item > .fr h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
    color: #292929;
}
.tab_item > .fr h5 > span {
    float: right;
    position: relative;
    font-weight: normal;
    margin-left: 200px;
    font-size: 14px;
    color: #292929;



}
.tab_item > .fr h5 > span.included-database {
    /* display: flex; */
}
.tab_item > .fr h5 > span.included-database span{
    float: left;
    height: 22px;
    margin-left: 10px;
    display: block;
    font-size: 12px;
    color: #BD1A2D;
    padding: 0 10px;
    line-height: 22px;
    border: 0.8px solid #A91728;
    box-sizing: border-box;
    border-radius: 10px;
    white-space: nowrap;
}
/* .tab_item > .fr h5 > span::before {
    position: absolute;
    top: 4px;
    left: -30px;
    content: '';
    width: 18px;
    height: 18px;
    background: url(../assets/img/u775.png) no-repeat;
    background-size: 100% 100%;
} */
.tab_item > .fr p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
    color: #292929;
}
.tab_item > .fr p.abs {
    margin-top: 10px;
    /*text-indent: 2em;*/
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.tab_item > .fr p > span {
    margin-left: 50px;
}
.tab_item > .fr p > span:first-child {
    margin-left: 0;
}
.tree_box {
    width: 280px;
    margin-left: 20px;
    padding: 0 12px;
    /*position: relative;*/
    border-top: 2px solid #BD1A2D;
    margin-bottom: 20px;
}
.tree_box.on {
    border: 1px solid #d1d1d1;
    border-top: 2px solid #BD1A2D;
    background: #fff;
    box-shadow: 8px 8px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 36px;
}
.tree_sort {
    position: relative;
    top: -10px;
    left: 15px;
    width: 200px;
    margin-top: -20px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    /*color: #bd8872;*/
    /*border-bottom: 2px solid #BD1A2D;*/
    cursor: pointer;
    color: #fff;
    background: #BD1A2D;
}
.tree_sort > i {
    float: left;
    width: 18px;
    height: 18px;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    background: url('../assets/img/icon_torr_close.png') no-repeat;
    background-size: 100% 100%;
}
.tree_sort > i.on {
    background-image: url('../assets/img/icon_torr_down.png');
}
.sort_box {
    position: relative;
    width: 245px;
    /*border-top: 1px solid #ccc;*/
}
.sort_box > p {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-indent: 22px;
    border-bottom: 1px dashed #f0f0f0;
    /*background: #f5f5f5;*/
    cursor: pointer;
}
.sort_box > p:last-child {
    border: none;
}
.sort_box > p:before {
    content: '';
    float: left;
    margin-top: 17px;
    margin-left: 25px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #b5b5b5;
    border-radius: 50%;
}
.sort_box > p.all:before {
    display: none;
}

.sort_box > p.all {
    font-size: 16px;
    text-indent: 0;
    /*color: #fff;*/
    /*border-top: 1px solid #fff;*/
    color: #55b9c1;
    /*background: #b89781;*/
}
.sort_box > p i {
    display: none;
}
.sort_box > p.all i {
    display: block;
    float: left;
    width: 14px;
    height: 14px;
    /*margin-top: 8px;*/
    margin-left: 10px;
    /*background: url("../assets/img/icon_left.png") no-repeat;*/
    background-size: 100% 100%;
}
td a {
    color: #292929;
}
</style>
